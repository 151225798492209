//
//
//
//
//
//
//

import { Icon } from '@iconify/vue2';

export default {
  name: "ButtonIcon",
  components: { Icon },
  props: {
    variant: {
      type: String,
      default: "orange-light"
    },
    circle: Boolean,
    size: String,
    icon: String,
    iconWidth: {
      type: Number,
      default: 24
    }
  },
  computed:{
    btnSize(){
      switch(this.size){
        case "lg":
          return 60;
        default: 
          return 40
      }
    },
    iconSize(){
      switch(this.size){
        case "lg":
          return 36;
        default: 
          return 24
      }
    }
  }
};
