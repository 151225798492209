//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
    name: "SectionJoin",
    props: {
        title: String
    },
};
