//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: "SectionDividerCanoe",
    functional: true,
    props: {
        background: {
            type: String,
            default: "",
            validator(value) {
                return value ? ['pale'].includes(value) : true;
            }
        }
    }
};
