//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { BForm, BFormGroup, BFormInvalidFeedback, BFormInput, BSpinner } from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import VueRecaptcha from 'vue-recaptcha';
import { waitForCondition } from '~/utils';

export default {
    name: "NewsletterForm",
    components: { ValidationProvider, ValidationObserver, VueRecaptcha, BForm, BFormGroup, BFormInvalidFeedback, BFormInput, BSpinner },
    props: {
        inline: Boolean
    },
    data() {
        return {
            form: {
                email: "",
            },
            captchaState: null,
            captchaResponse: null,
            formSubmitting: false,
            formError: ""
        };
    },
    methods: {
        onSubmit: async function () {
            if (this.formSubmitting) return;

            this.formError = "";

            const formValid = await this.$refs.observer.validate();
            this.$refs.recaptcha.execute();
            await waitForCondition(() => { return this.captchaResponse || this.captchaState === false }, -1);

            if (!this.captchaResponse || !formValid) {
                return;
            }

            this.formSubmitting = true;
            try {
                await this.$api.contact.acAddContact({
                    email: this.form.email,
                    captcharesponse: this.captchaResponse,
                    form: "143",
                    ref: this.$trackingVars.ref(),
                    prevsite: this.$trackingVars.prevSite(),
                    device: this.$trackingVars.deviceType(),
                    url: window.location.href
                });
                this.$router.push(this.localePath("/confirmacion-suscripcion"))
            }
            catch (e) {
                this.formError = "Ha ocurrido un error, por favor vuelve a intentar en unos segundos."
                console.error(e);
            }
            finally {
                this.formSubmitting = false;
            }
        },
        onReset() {
            this.form.email = "";
            this.$refs.observer.reset();
        },
        resetForm() {
            this.$refs.form.reset();
        },
        getValidationState({ dirty, validated, valid = null }) {
            return dirty || validated ? valid : null;
        },
        onCaptchaVerify(response) {
            this.captchaState = true;
            this.captchaResponse = response;
        },
        onCaptchaError() {
            this.captchaState = false;
            this.captchaResponse = null;
        },
        onCaptchaExpired() {
            // this.captchaState = false;
            this.captchaResponse = null;
        }

    }
};
