//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { BNav, BNavItem } from 'bootstrap-vue';

import { Icon } from '@iconify/vue2';

export default {
    name: "Footer",
    components: { BNav, BNavItem, Icon }
}
