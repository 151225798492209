let injectTimeout;

// Trigger loading of recaptcha.js script
let injectReCaptcha = () => {
    document.removeEventListener('focus', onDocumentFocusHandler, true)
    if (injectTimeout) clearTimeout(injectTimeout);
    if (!document.querySelector("script[src^=\"https://www.google.com/recaptcha/api.js\"]")) {
        var head = document.getElementsByTagName('head')[0];
        var script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = 'https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit';
        head.appendChild(script);
    }
};

const onDocumentFocusHandler = (event)=>{
    if(event.target.closest("form")){
        injectReCaptcha();
    }
};

document.addEventListener('focus', onDocumentFocusHandler,  { capture: true });
injectTimeout = setTimeout(injectReCaptcha, 10000)
