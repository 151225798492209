//
//
//
//
//
//
//
//
//
//
//
//
//

let modalTimeout;

export default {
  head() {
    const i18nHead = this.$nuxtI18nHead({ addSeoAttributes: true })
    return {
      htmlAttrs: {
        ...i18nHead.htmlAttrs
      },
      meta: [
        ...i18nHead.meta,
      ],
      link: [
        ...i18nHead.link,
      ]
    }
  },
  mounted() {
    /*modalTimeout = setTimeout(() => {
      const showModal = window.sessionStorage.getItem("showPromoModal");
      if (!showModal) {
        this.$refs.promoModal.showModal();
        window.sessionStorage.setItem("showPromoModal", true);
      }
    }, 2500);*/
  },
  watch: {
    $route(to, from) {
    },
  },
}
