import { NuxtAxiosInstance } from '@nuxtjs/axios/types';

class ApiCotizacion {
    apiClient: NuxtAxiosInstance;
    baseURL: string;

    constructor(axios: NuxtAxiosInstance, baseURL: string) {
        this.baseURL = baseURL;
        this.apiClient = axios.create({
            baseURL: this.baseURL,
            withCredentials: false,
            headers: {
                'Content-Type': 'text/xml; charset=utf-8',
            }
        });
    }

    async getCotizacion(data: GetCotizacionParams) {
        return await this.apiClient.$get("/cotizacion", { params: data });
    }

}

interface GetCotizacionParams {
    monedas?: Array<number>,
    fechaDesde?: Date,
    fechaHasta?: Date,
    grupo?: number
}

export default (axios: NuxtAxiosInstance, baseURL: string) => new ApiCotizacion(axios, baseURL)


