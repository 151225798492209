//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { BCard, BCardTitle, BCardBody } from "bootstrap-vue";

export default {
  name: "SectionContact",
  components: { BCard, BCardTitle, BCardBody },
  props: {
    title: String
  },
  data() {
    return {

    }
  }
};
