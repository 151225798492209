import { NuxtAxiosInstance } from '@nuxtjs/axios/types';

class ContactApi {
    apiClient: NuxtAxiosInstance;
    baseURL: string;

    constructor(axios: NuxtAxiosInstance, baseURL: string) {
        this.baseURL = baseURL;
        this.apiClient = axios.create({
            baseURL: this.baseURL,
            withCredentials: false,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        });
    }

    async acAddContact(contactInfo: AcContactData) {
        return this.apiClient.$post("/activecampaign-add-update-contact", contactInfo);
    }
}

export default (axios: NuxtAxiosInstance, baseURL: string) => new ContactApi(axios, baseURL)

interface AcContactData {
    email: String,
    name?: String,
    course?: String,
    form?: String,
    comments?: String,
    queryType?: Array<String>,
    gdpr2019?: String,
    ref?: String,
    prevsite?: String,
    device?: String,
    captcharesponse: String,
    url?: String
}

