//
//
//
//
//
//
//
//
//
//

import { Icon } from '@iconify/vue2';

export default {
  name: "ListIcon",
  components: { Icon },
  props: {
    icon: {
      type: String,
      default: "charm:tick"
    },
    items: Array,
    variant: {
      type: String,
      default: "primary"
    },
    columns: {
      type: Number,
      default: 1
    },
    items: Array,
    itemClass: String,
    iconClass: String
  }
};
