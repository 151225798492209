var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('autocomplete',{attrs:{"search":_vm.search,"get-result-value":_vm.getResultValue,"autoSelect":true,"debounceTime":500},on:{"submit":_vm.onSubmitSearch,"update":_vm.onUpdate},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var rootProps = ref.rootProps;
  var inputProps = ref.inputProps;
  var inputListeners = ref.inputListeners;
  var resultListProps = ref.resultListProps;
  var resultListListeners = ref.resultListListeners;
  var results = ref.results;
  var resultProps = ref.resultProps;
return [_c('div',_vm._b({},'div',rootProps,false),[_c('div',{staticClass:"d-flex align-items-stretch"},[_c('label',{staticClass:"d-none",attrs:{"for":_vm.inputId}},[_vm._v("Encuentra tu próximo curso")]),_vm._v(" "),_c('input',_vm._g(_vm._b({class:[
          'autocomplete-input form-control form-control-lg border-0',
          { 'autocomplete-input-no-results': _vm.noResults }
        ],attrs:{"maxlength":"40","placeholder":"Encuentra tu próximo curso","spellcheck":"true","id":_vm.inputId},on:{"input":_vm.onInput}},'input',inputProps,false),inputListeners))]),_vm._v(" "),(_vm.loading)?_c('ul',{staticClass:"autocomplete-result-list",staticStyle:{"position":"absolute","z-index":"1","width":"100%","top":"100%"}},[_c('li',{staticClass:"autocomplete-result no-result d-flex justify-content-center"},[_c('b-spinner')],1)]):_vm._e(),_vm._v(" "),(!_vm.loading && _vm.noResults)?_c('ul',{staticClass:"autocomplete-result-list",staticStyle:{"position":"absolute","z-index":"1","width":"100%","top":"100%"}},[_c('li',{staticClass:"autocomplete-result no-result"},[_vm._v("\n          No se encontró ningún resultado para tu búsqueda. "),_c('br'),_vm._v("\n          Quizás te interesen estos cursos:\n        ")]),_vm._v(" "),_vm._l((_vm.courseListNoResult),function(course){return _c('li',{key:course.name,staticClass:"autocomplete-result"},[_c('nuxt-link',{attrs:{"to":course.to}},[_c('img',{attrs:{"src":course.img,"loading":"lazy"}}),_vm._v(" "),_c('span',{domProps:{"innerHTML":_vm._s(_vm.replaceMatchingText(course.name, inputProps.value))}})])],1)})],2):_vm._e(),_vm._v(" "),(!_vm.loading)?_c('ul',_vm._g(_vm._b({},'ul',resultListProps,false),resultListListeners),_vm._l((results),function(result,index){return _c('li',_vm._b({key:resultProps[index].id},'li',resultProps[index],false),[_c('nuxt-link',{attrs:{"to":result.to}},[_c('img',{attrs:{"src":result.img,"loading":"lazy"}}),_vm._v(" "),_c('span',{domProps:{"innerHTML":_vm._s(_vm.replaceMatchingText(result.name, inputProps.value))}})])],1)}),0):_vm._e()])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }