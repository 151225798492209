//
//
//
//
//
//

export default {
name: "ConfirmacionContacto",
head() {
  return {
    title: 'Gracias por contactarnos',
    meta: [
      { hid: 'description', name: 'description', content: '' },
      { hid: 'keywords', name: 'keywords', content: '' },
      { hid: 'og:image', property: 'og:image', content: require('~/assets/img/abstracta-academy-meta-data.jpg') },
    ]
  }
},
};
