//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { BFormCheckboxGroup, BFormCheckbox, BDropdown, BDropdownItem, BDropdownText } from 'bootstrap-vue';
import { CourseCategory, CourseLanguage } from '~/types/course';
import { Icon } from '@iconify/vue2';
import { getAllCourses } from '~/data/courses'

export default {
  name: "Cursos",
  layout: "default",
  components: { BFormCheckboxGroup, BFormCheckbox, BDropdown, BDropdownItem, Icon, BDropdownText },
  async asyncData() {
    const courses = await getAllCourses();
    return { courseList: Object.values(courses) }
  },
  head() {
    return {
      title: 'Cursos de testing de software',
      meta: [
        { hid: 'description', name: 'description', content: 'Explora nuestros cursos de testing funcional, automatización de pruebas, pruebas de performance, pruebas de accesibilidad y más.' },
        { hid: 'keywords', name: 'keywords', content: 'curso testing' },
        { hid: 'og:image', property: 'og:image', content: require('~/assets/img/abstracta-academy-meta-data.jpg') },
      ]
    }
  },
  data() {
    return {
      filters: {
        language: [],
        duration: [],
        category: CourseCategory.ALL
      },
      categories: Object.values(CourseCategory),
      CourseLanguage,
      CourseCategory
    }
  },
  computed: {
    filteredCourseList() {
      return this.courseList.filter(value => this.categoryFilter(value) && this.languageFilter(value) && this.durationFilter(value))
    },
  },
  methods: {
    getCategory(id) {
      return this.categories.find(c => c.id == id);
    },
    categoryFilter(course) {
      if (this.filters.category == CourseCategory.ALL) return true;
      else {
        return course.category == this.filters.category;
      }
    },
    languageFilter(course) {
      if (!this.filters.language.length) return true;
      return this.filters.language.find(l => l == course.language)
    },
    durationFilter(course) {
      const courseDuration = course.duration;
      const filterVal = this.filters.duration;
      if (!filterVal.length) return true;
      else {
        return (filterVal.find(i => i == '1') && courseDuration.hours && courseDuration.hours <= 8) ||
          (filterVal.find(i => i == '2') && courseDuration.hours && courseDuration.hours >= 9 && courseDuration.hours <= 16) ||
          (filterVal.find(i => i == '3') && courseDuration.hours && courseDuration.hours >= 17 && courseDuration.hours <= 32) ||
          (filterVal.find(i => i == '4') && (courseDuration.hours && courseDuration.hours >= 32 || courseDuration.weeks || courseDuration.months))
      }
    },
  },
  mounted() {
    if (this.$route.query.category) {
      const matchCategory = Object.keys(CourseCategory).find((c) => CourseCategory[c] == this.$route.query.category);
      if (matchCategory) this.filters.category = CourseCategory[matchCategory];
    }
  }
};
