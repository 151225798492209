//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
    name: "SectionFeaturedCourses",
    props: {
        courseList: Array
    },
};
