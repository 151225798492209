//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "SectionClientSlider",
  props: {
    title: String,
    logos: Array
  },
  data() {
    return {
      logosList: [
      {
          url: require("~/assets/img/confian-logo-utec.png?webp"),
          alt: "Logo Utec",
          site: "https://utec.edu.uy/es/"
        },
        {
          url: require("~/assets/img/confian-logo-inefop.png?webp"),
          alt: "Logo de INEFOP",
          site: "https://www.inefop.uy/"
        },
        {
          url: require("~/assets/img/confian-logo-tee.png?webp"),
          alt: "Logo The Everything Else",
          site: "https://open.spotify.com/show/5H59osSvbCKa7hM5K80ITI"
        },
        {
          url: require("~/assets/img/confian-logo-jovenes-a-programar.png?webp"),
          alt: "Logo de Jóvenes a Programar",
          site: "https://jovenesaprogramar.edu.uy/"
        },
        {
          url: require("~/assets/img/confian-logo-reconvertite.png?webp"),
          alt: "Logo de ReconverTIte",
          site: "https://reconvertite.uy/"
        },
        {
          url: require("~/assets/img/confian-logo-fundacion-emplea.png?webp"),
          alt: "Logo de Fundacion Emplea",
          site: "https://fundacionemplea.cl/"
        },
        {
          url: require("~/assets/img/confian-logo-pyxis.png?webp"),
          alt: "Logo de Pyxis programa 50",
          site: "https://pyxis.tech/"
        },
        {
          url: require("~/assets/img/confian-logo-upcamp.png?webp"),
          alt: "Logo de UpCamp",
          site: "https://es.abstracta.us/blog/upcamp-modelo-formacion-competencias-ti/"
        },
        {
          url: require("~/assets/img/confian-logo-fe-y-alegria.png?webp"),
          alt: "Logo de Fe y Alegría",
          site: "https://www.feyalegria.org/uruguay/"
        },
        {
          url: require("~/assets/img/confian-logo-casa-mujer.png?webp"),
          alt: "Logo de Casa de la Mujer",
          site: "https://www.casadelamujer.org.uy/"
        },
      ]
    }
  }
};
