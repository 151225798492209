import { render, staticRenderFns } from "./CourseAutocomplete.vue?vue&type=template&id=7f55c410&scoped=true"
import script from "./CourseAutocomplete.vue?vue&type=script&lang=js"
export * from "./CourseAutocomplete.vue?vue&type=script&lang=js"
import style0 from "./CourseAutocomplete.vue?vue&type=style&index=0&id=7f55c410&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7f55c410",
  null
  
)

export default component.exports