//
//
//
//
//
//
//
//
//
//

import { BCard, BCardBody } from 'bootstrap-vue';
import { Icon } from '@iconify/vue2';

export default {
    name: "CourseCardSummary",
    components: { BCard, BCardBody, Icon },
    props: {
        courseCardPhase: String,
    }
};
