//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Icon } from '@iconify/vue2';

export default {
  name: "HeaderConfirmation",
  props: {
    title: String,
    subtitle: String
  },
  components: { Icon }
};
