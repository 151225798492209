import { render, staticRenderFns } from "./Footer.vue?vue&type=template&id=3ab4588f&scoped=true"
import script from "./Footer.vue?vue&type=script&lang=js"
export * from "./Footer.vue?vue&type=script&lang=js"
import style0 from "./Footer.vue?vue&type=style&index=0&id=3ab4588f&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ab4588f",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Footer: require('/builds/marketing/abstracta.academy-website/components/Footer.vue').default})
