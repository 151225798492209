//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { BCard, BCardBody } from 'bootstrap-vue';
import { Icon } from '@iconify/vue2';

export default {
    name: "CourseCardSummary",
    components: { BCard, BCardBody, Icon },
    props: {
        description: Array,
        topics: Array,
    },
    data() {
        return {
            paymentMethod: ["paypal"]
        }
    },
    methods: {
        onBtnClick() {
            let payment = document.querySelectorAll('input[type="checkbox"]:checked');
            sessionStorage.setItem('coursePaymentMethod', payment[0].value);

            if (payment[0].value == "paypal" ) {
                this.$router.push('/es/concretar-compra/');
            }
            
            this.$emit("btnClick");
        },
    },
    mounted() {
        this.paymentMethod = sessionStorage.coursePaymentMethod;
    },
};
