import { render, staticRenderFns } from "./failure.vue?vue&type=template&id=27f3ba33"
import script from "./failure.vue?vue&type=script&lang=js"
export * from "./failure.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HeaderConfirmation: require('/builds/marketing/abstracta.academy-website/components/HeaderConfirmation.vue').default})
