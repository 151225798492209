import { render, staticRenderFns } from "./politica-de-privacidad.vue?vue&type=template&id=2fd6c113&scoped=true"
import script from "./politica-de-privacidad.vue?vue&type=script&lang=js"
export * from "./politica-de-privacidad.vue?vue&type=script&lang=js"
import style0 from "./politica-de-privacidad.vue?vue&type=style&index=0&id=2fd6c113&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2fd6c113",
  null
  
)

export default component.exports