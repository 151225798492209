//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//



export default {
  name: "TestimonySlider",
  components: {},
  props: {
    testimonyList: {
      type: Array,
      required: true,
    },
    lazy: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      options: {
        perPage: 2,
        arrows: false,
        gap: "1.5rem",
        breakpoints: {
          768: {
            perPage: 1,
          },
          1199: {
            perPage: 1,
          },
        },
      },
    };
  },
};
