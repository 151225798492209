// Runs once to get url params and save them in sessionStorage

const getParameterByName = (e, r) => {
    r || (r = window.location.href), (e = e.replace(/[\[\]]/g, "\\$&"));
    var t = new RegExp("[?&]" + e + "(=([^&#]*)|&|#|$)").exec(r);
    return t ? (t[2] ? decodeURIComponent(t[2].replace(/\+/g, " ")) : "") : null;
}

const getDeviceType = () => {
    return navigator.userAgent.match(/Tablet|iPad|iPod|Android/i) &&
        window.innerWidth <= 1280 &&
        window.innerHeight <= 800 &&
        !navigator.userAgent.match(/Mobile/i)
        ? "Tablet"
        : navigator.userAgent.match(
            /Android|BlackBerry|Tablet|Mobile|iPhone|iPad|iPod|Opera Mini|IEMobile/i
        )
            ? "Mobile"
            : "Computer";
}


export default (ctx, inject) => {
    const currentUrl = new URL(window.location.href);
    const refParam = currentUrl.searchParams.get("ref");
    if(refParam && !window.sessionStorage.getItem("ref")) {
        window.sessionStorage.setItem("ref", refParam);
    }
    if (!window.sessionStorage.getItem("prevsite")) {
        window.sessionStorage.setItem("prevsite", document.referrer || "direct traffic");
    }
    window.sessionStorage.setItem("deviceType", getDeviceType());

    inject('trackingVars', {
        ref(){
            return window.sessionStorage.getItem("ref") || "";
        },
        prevSite(){
            return window.sessionStorage.getItem("prevsite") || "";
        },
        deviceType(){
            return window.sessionStorage.getItem("deviceType") || "";
        }
    })

}