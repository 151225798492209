import { render, staticRenderFns } from "./index.vue?vue&type=template&id=4633e68f&scoped=true"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
import style0 from "./index.vue?vue&type=style&index=0&id=4633e68f&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4633e68f",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HeaderMain: require('/builds/marketing/abstracta.academy-website/components/HeaderMain.vue').default})
