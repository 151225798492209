//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { BCard, BCardText, BBadge } from 'bootstrap-vue';
import { Icon } from '@iconify/vue2';

export default {
    name: "InstructorCard",
    components: { BCard, BCardText, BBadge, Icon },
    props: {
        name: String,
        linkedInUrl: String,
        tags: Array,
        description: String,
        img: String,
        lazy: Boolean
    },
    methods: {
        getLanguage(language) {
            if (language == "es") return 'Español';
            if (language == "en") return 'Inglés';
        },
    }
};
