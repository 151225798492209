//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { BCard, BCardBody } from 'bootstrap-vue';

export default {
  name: "Contacto",
  components: { BCard, BCardBody },
  layout: 'contacto',
  head() {
    return {
      title: 'Contacto',
      meta: [
        { hid: 'description', name: 'description', content: 'Descubre los beneficios de estudiar online, y adquiere el mayor conocimiento en el menor tiempo posible de manera efectiva.' },
        { hid: 'keywords', name: 'keywords', content: 'abstracta academy contacto' },
        { hid: 'og:image', property: 'og:image', content: require('~/assets/img/abstracta-academy-meta-data.jpg') },
      ]
    }
  },
};
