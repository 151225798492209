//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { BCard, BCardBody } from 'bootstrap-vue';
import { Icon } from '@iconify/vue2';

export default {
    name: "CourseCardAboutInstructors",
    components: { BCard, BCardBody, Icon },
    props: {
        category: String,
        courseBioInstructors: Array,
    }
};
