//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  name: "LogoSlider",
  components: {},
  props: {
    logosList: {
      type: Array,
      required: true,
    },
    lazy: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      options: {
        arrows: true,
        pagination: false,
        focus: "center",
        autoWidth: true,
        autoplay: true,
        interval: 2500,
        pauseOnHover: false,
        resetProgress: true,
        rewind: true
      },
    };
  },
};
