//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  name: "LinkBtnIcon",
  components: {  },
  props: {
    href: String,
    to: String || Object,
    variant: {
      type: String,
      default: "primary"
    },
    icon: String,
    size: String,
    btnVariant: {
      type: String,
      default: "orange-light"
    },
    btnClass: String
  }
};
