//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { BCard, BCardBody } from 'bootstrap-vue';
import ListIcon from './ListIcon'; 

export default {
    name: "CourseCardSpecialization",
    components: { BCard, BCardBody, ListIcon },
    props: {
        coursePackList: {
            type: Array,
            required: true
        }
    },
    computed: {
        formattedCoursePackList() {
            return this.coursePackList.map(course => {
                return { 
                    text: course.name, 
                    icon: 'charm:tick',
                    url: course.url 
                };
            });
        }
    }
};
