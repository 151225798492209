//
//
//
//
//
//
//
//
//

import { BModal } from 'bootstrap-vue';

export default {
  name: "PromoModal",
  components: { BModal },
  props: {
    title: {
      type: String,
      required: true
    },
    imgSrc: {
      type: String,
      required: true
    },
    imgAlt: "",

  },
  data() {
    return {
      modalShow: false
    }
  },
  methods: {
    showModal() {
      this.modalShow = true;
    },
    hideModal() {
      this.modalShow = false;
    }
  }
};
