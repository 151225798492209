//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { BSpinner } from 'bootstrap-vue';
import '@trevoreyre/autocomplete-vue/dist/style.css'
import Autocomplete from '@trevoreyre/autocomplete-vue';
import stringSimilarity from 'string-similarity';
import { v4 as uuidv4 } from 'uuid';

export default {
  name: "CourseAutocomplete",
  props: {
    courseList: Array,
    courseListNoResult: Array,
  },
  components: { Autocomplete, BSpinner },
  data() {
    return {
      noResults: false,
      searchInput: "",
      loading: false,
      inputId: uuidv4()
    };
  },
  methods: {
    search(input) {
      this.noResults = false;

      if (input.length < 2) {
        return []
      }
      const ratings = this.courseList.map((c) => { return [stringSimilarity.compareTwoStrings(input.toLowerCase(), c.name.toLowerCase() + " " + c.category.toLowerCase()), c] })
      const minVal = input.length <= 4 ? 0.08 : 0.2;
      const results = ratings.filter(([r, c]) => { return r > minVal }).sort((a, b) => b[0] - a[0]).map(([r, c]) => c);
      this.noResults = results.length == 0;
      return results;
    },
    onUpdate(results, selectedIndex) {
      this.loading = false;
    },
    onInput(event) {
      if (event.target.value.length >= 2)
        this.loading = true;
      else this.loading = false;
    },
    getResultValue(result) {
      return result.name;
    },
    replaceMatchingText(text, match) {
      return text.replace(new RegExp(`(${match})`, 'i'), '<span class="text-primary font-weight-bold">$1</span>');
    },
    onSubmitSearch(result) {
      this.$router.push(result.to);
    }
  }
};
