//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { BCard, BCardHeader, BCardText, BCardBody, BCollapse } from 'bootstrap-vue';
import { Icon } from '@iconify/vue2';

export default {
  name: "Accordion",
  components: { BCard, BCardHeader, BCardText, BCardBody, BCollapse, Icon },
  props: {
    title: String,
    content: String,
    id: {
      type: String,
      required: true
    },
    group: String,
    titleClass: String
  }
};
