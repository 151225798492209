//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "SectionTestimonies",
  props: {
    title: String,
    testimonyList: Array
  },
  data() {
    return {}
  }
};
