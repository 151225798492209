import {
    extend,
    localize,
} from "vee-validate";
import es from "vee-validate/dist/locale/es.json";
import * as rules from "vee-validate/dist/rules";

// Install VeeValidate rules and localization
Object.keys(rules).forEach(rule => {
    extend(rule, rules[rule]);
});

extend('selectOne', {
    validate(value) {
        return value && value.length >= 1 || "Selecciona una opción";
    },
    computesRequired: true
});

// extend('phone', {
//     validate(value) {
//         return value && /[+]?[0-9|\-|\s]*/.test(value) || "";
//     },
//     computesRequired: true
// });

localize("es", es);
