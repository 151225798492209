//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { BCol, BContainer, BRow, BCard, BCardText } from 'bootstrap-vue';

export default {
  name: 'CustomTrainingPage',
  layout: "default",
  head() {
    return {
      title: 'Impacto social',
      meta: [
        { hid: 'description', name: 'description', content: '¡Creamos Abstracta Academy porque el mundo necesita más y mejores testers!' },
        { hid: 'keywords', name: 'keywords', content: 'abstracta academy' },
        { hid: 'og:image', property: 'og:image', content: require('~/assets/img/abstracta-academy-meta-data.jpg') },
      ]
    }
  },
  data() {
    return {
      testimonyList: [
        {
          name: "Diego Sastre",
          position: "CEO de Pyxis",
          text: `"Confiamos en Abstracta Academy para nuestra primera edición del <span class="text-primary">Programa +50</span>.  A través de esta experiencia, capacitamos en testing a 10 adultos de más de 45 años, con el objetivo de brindarles herramientas para reinsertarse en el mercado laboral. El curso a cargo de Abstracta fue una excelente puerta de entrada al mundo del testing para los participantes".`,
          photo: require("~/assets/img/testimony-photo-diego-sastre.png?webp"),
          iconCountry: require("~/assets/img/icon-flag-uruguay.svg"),
        },
        {
          name: "Milagros Olivera",
          position: "Software Tester Egresada de ReconverTIte",
          text: `"Como participante de <a href="http://reconvertite.uy" target="_blank">ReconverTIte<a>, con Abstracta Academy realicé mi primera capacitación 100% online y fue realmente una gran experiencia, me sentí siempre acompañada. Los cursos son muy dinámicos y con diferentes contenidos como videos explicativos, desafíos, actividades. ¡Academy colmó mis expectativas!".`,
          photo: require("~/assets/img/testimony-photo-milagros-olivera.png?webp"),
          iconCountry: require("~/assets/img/icon-flag-uruguay.svg"),
        },
        {
          name: "Ricardo Délano",
          position: "Director Ejecutivo en Fundación Emplea, 2021",
          text: `"Gracias al diseño e implementación del programa Hub Testing junto a Abstracta Academy, estamos impulsando la activación laboral de 40 jóvenes de distintas regiones de Chile que buscan iniciar su carrera en el área de tecnologías de información. Desarrollando habilidades y competencias acorde a lo que exige el mercado del trabajo. Esperamos convocar a más actores para revolucionar el mercado del trabajo desde la base de la pirámide".`,
          photo: require("~/assets/img/testimony-photo-ricardo-delano.png?webp"),
          iconCountry: require("~/assets/img/icon-flag-chile.svg"),
        },
        {
          name: "Ing. Carinna Bálsamo, MBA",
          position: "Directora de Jóvenes a Programar",
          text: `"Con el apoyo de Abstracta Academy, <span class="text-primary">Jóvenes a Programar (JaP)</span> pudo escalar su capacitación en testing para jóvenes de todo el país logrando así capacitarlos en los fundamentos de esta disciplina que toma cada vez más relevancia en el desarrollo de software. En particular, la base del temario del curso de testing de <span class="text-primary">JaP</span> ha sido definida a partir de contenidos que Abstracta Academy puso a disposición para el programa".`,
          photo: require("~/assets/img/testimony-photo-carina-balsamo.png?webp"),
          iconCountry: require("~/assets/img/icon-flag-uruguay.svg"),
        },
      ],
    }
  }
};
