
export default {
  name: "FieldFloatingLabel",
  // Create as a functional component wrapper
  functional: true,
  render(createElement, context) {
    return createElement("div", { attrs: { class: "custom-input" } }, [
      context.children,
      createElement("label", {
        domProps: { innerHTML: context.props.label },
        attrs: { for: context.children[0].componentOptions.propsData.id || null },
      }),
    ]);
  },
};
