//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
    name: "SectionBePart",
    props: {
    },
};
