import { render, staticRenderFns } from "./confirmacion-contacto.vue?vue&type=template&id=9034bfec"
import script from "./confirmacion-contacto.vue?vue&type=script&lang=js"
export * from "./confirmacion-contacto.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HeaderConfirmation: require('/builds/marketing/abstracta.academy-website/components/HeaderConfirmation.vue').default})
