//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Icon } from '@iconify/vue2';

export default {
  name: "LinkIcon",
  components: { Icon },
  props: {
    href: String,
    to: [String, Object],
    variant: {
      type: String,
      default: "primary"
    },
    icon: String,
    iconWidth: {
      type: Number,
      default: 24
    },
    size: String
  }
};
