import Vue from 'vue';

let _topLimit = 0, _bottomLimit = 99999, _topGap = 0, _bottomGap = 0;
let lastScrollTop;
let _el;
const rePosition = (force = false) => {
    const cardHeight = _el.offsetHeight;
    const { top: cardOffsetTop, bottom: cardOffsetBottom } = _el.getBoundingClientRect();
    const windowScrollTop = document.documentElement.scrollTop;
    const vwTopLimit = cardOffsetTop > _topGap;
    const vwBottomLimit = window.innerHeight > cardOffsetBottom + _bottomGap;

    const stopAt = _bottomLimit - cardHeight - _topLimit;

    if ((force || lastScrollTop < windowScrollTop) && vwBottomLimit) {
        _el.style.top = Math.min(windowScrollTop - (cardHeight - window.innerHeight) - _topLimit - _bottomGap, stopAt) + "px";
    }
    else if ((force || lastScrollTop > windowScrollTop) && vwTopLimit) {
        _el.style.top = Math.max((windowScrollTop - _topLimit) + _topGap, 0) + "px";
    }
    lastScrollTop = windowScrollTop;
}
const onResizeHandler = () => { rePosition(false) };

Vue.directive('scroll-drag', {
    bind: (el, { value: { topLimit = 0, bottomLimit = Number.MAX_VALUE, topGap = 0, bottomGap = 0 } }) => {
        lastScrollTop = document.documentElement.scrollTop
        _el = el;
        _topLimit = topLimit;
        _bottomLimit = bottomLimit;
        _topGap = topGap;
        _bottomGap = bottomGap;
        window.addEventListener("scroll", onResizeHandler, { passive: true });
    },
    componentUpdated: (el, { value: { topLimit = 0, bottomLimit = Math.max, topGap = 0, bottomGap = 0 } }) => {
        _el = el;
        _topLimit = topLimit;
        _bottomLimit = bottomLimit;
        _topGap = topGap;
        _bottomGap = bottomGap;
        rePosition(true);
    },
    unbind: () => {
        window.removeEventListener("scroll", onResizeHandler);
    }
})
