//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "HeaderMain",
  props: {
    alignItems: String,
  }
};
