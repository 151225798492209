import { render, staticRenderFns } from "./SectionDividerCanoe.vue?vue&type=template&id=34a45194&scoped=true&functional=true"
import script from "./SectionDividerCanoe.vue?vue&type=script&lang=js"
export * from "./SectionDividerCanoe.vue?vue&type=script&lang=js"
import style0 from "./SectionDividerCanoe.vue?vue&type=style&index=0&id=34a45194&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "34a45194",
  null
  
)

export default component.exports