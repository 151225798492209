//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "HeaderCourse",
  props: {
    name: String,
    subtitle:String,
    category: Object,
  },
  data(){
    return {
      breadcrumb: [
          {
            text: 'Cursos',
            to: {path: './'}
          },
          {
            text: 'Otros',
            
          },
          {
            text: 'OrgaNICE!',
            active: true
          }
        ]
    }
  }
};
