//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { BCard, BCardText, BCardHeader, BCardBody, BCollapse, BInputGroup, BFormInput, BForm } from 'bootstrap-vue';
import { Icon } from '@iconify/vue2';
import stringSimilarity from 'string-similarity';

export default {
  name: "Instructores",
  layout: "default",
  components: { BCard, BCardText, BCardHeader, BCardBody, BCollapse, BInputGroup, BFormInput, BForm, Icon },
  head() {
    return {
      title: 'Preguntas Frecuentes',
      meta: [
        { hid: 'description', name: 'description', content: '¿Cómo funcionan los cursos en Abstracta Academy? ¿Todos los curso son relacionados a testing de software? Respondemos tus preguntas aquí.' },
        { hid: 'keywords', name: 'keywords', content: 'abstracta academy preguntas frecuentes' },
        { hid: 'og:image', property: 'og:image', content: require('~/assets/img/abstracta-academy-meta-data.jpg') },
      ]
    }
  },
  data() {
    return {
      searchValue: "",
      faqList: [
        {
          question: "¿Cómo funcionan los cursos?",
          response: `Una persona de nuestro equipo te acompañará de forma personalizada en cada etapa de tu proceso de aprendizaje.`
        },
        {
          question: "¿Cuándo puedo comenzar a realizar el curso?",
          response: `Una vez que completes la compra, tendrás acceso a todo el material disponible en la plataforma, y podrás avanzar a tu propio ritmo. No hay una fecha de inicio establecida. <br/><br/>Sin embargo, existen ciertos plazos que deberás cumplir una vez que comiences. Estos plazos están especificados en el detalle del curso en el catálogo y también te los recordaremos en la primera lección.`
        },
        {
          question: "¿Cómo hago para comprar un curso?",
          response: `Para comprar un curso, simplemente haz clic en el botón "Explorar el curso" que aparece en nuestra página, o envíanos un mensaje a través de la sección <a href="https://abstracta.academy/es/contacto" target="_blank">"Contáctanos"</a>. Te proporcionaremos todos los pasos a seguir.`
        },
        {
          question: "¿Los cursos cuentan con clases en vivo?",
          response: `No. Ofrecemos una experiencia de aprendizaje flexible y accesible, con contenidos y materiales disponibles en la plataforma las 24 horas del día. 
          <br/><br/>
          Si bien no brindamos clases en vivo en los cursos que puedes encontrar en nuestro <a href="https://abstracta.academy/es/cursos/" target="_blank">catálogo online</a>, contamos con diversos canales de comunicación directa y personalizada, para que puedas resolver tus dudas y avanzar en tu aprendizaje sin inconvenientes.  
          <br/><br/>
          En el caso de formaciones a medida, sí ofrecemos la opción de clases en vivo. No dudes en <a href="https://abstracta.academy/es/contacto" target="_blank">consultarnos</a>.`
        },
        {
          question: "¿Cómo se aprueban los cursos? ",
          response: `Cada curso incluye diversas instancias de evaluación, como cuestionarios y tareas entregables. Para obtener tu certificado, debes completar y aprobar estas evaluaciones con un promedio mínimo de 75 sobre 100.`
        },
        {
          question: "¿Qué métodos de pagos se aceptan?",
          response: `Trabajamos con PayPal y Mercado Pago. Si decides realizar el pago a través de PayPal, necesitarás una tarjeta habilitada para compras y saldo disponible.
            <br/><br/>
            Desde Uruguay, puedes optar por Mercado Pago y pagar el curso que elijas en hasta 12 cuotas sin interés.
            <br/><br/>
            <a href="https://abstracta.academy/es/contacto" target="_blank">Contáctanos</a> y te indicaremos los pasos a seguir.`
        },
        {
          question: "¿Cómo accedo a la plataforma?",
          response: `Para acceder a la plataforma, simplemente visita <a href="https://web.academy.abstracta.us/" target="_blank">web.academy.abstracta.us</a> y regístrate.`
        },
        {
          question: "Finalicé el curso ¿Y ahora?",
          response: `Si completaste tu curso de manera satisfactoria, recibirás un certificado emitido por Abstracta Academy. Esta certificación cuenta con el respaldo de <a href="https://es.abstracta.us/" target="_blank">Abstracta</a>, una empresa global líder en soluciones tecnológicas. Desde nuestra plataforma, podrás descargar tu certificado y, si lo deseas, compartirlo directamente en LinkedIn. 
          <br/><br/>
          Además, damos prioridad a nuestros estudiantes cuando Abstracta busca ampliar su equipo. Una vez que termines tu curso, no dudes en enviarnos tu CV si deseas que te consideremos en futuros procesos de selección.`
        },
        {
          question: "¿Qué conocimientos previos debo tener para realizar un curso?",
          response: `Los conocimientos previos necesarios varían según el curso en el que desees inscribirte. Cada curso tiene una clasificación de conocimientos esenciales que precisas tener para desempeñarte con éxito, adquirir nuevos conocimientos y desarrollar habilidades. Toda esta información está detallada en la página de cada curso. Si tienes alguna duda, no dudes en contactarnos.`
        },
        {
          question: "¿Cuánto tiempo tengo para completar los cursos?",
          response: `El tiempo para completar los cursos varía. El plazo de acceso a los contenidos está especificado en la página de cada curso.`
        },
        {
          question: "Una vez finalizado y aprobado un curso, ¿puedo continuar accediendo a los contenidos de forma indefinida?",
          response: `Cada curso tiene un plazo de vigencia especificado. Por favor, consulta la página del curso para obtener más detalles sobre el acceso a los contenidos.`
        },
        {
          question: "¿Con qué curso debería empezar si no tengo conocimientos previos relacionados al área de la tecnología?",
          response: `Si te interesa dar tus primeros pasos en el área de tecnología, te recomendamos el curso <a href="https://abstracta.academy/es/cursos/software-tester/" target="_blank">Software Tester</a>. Este curso no requiere conocimientos previos y es una introducción al mundo del testing. En la página del curso podrás encontrar todos los detalles.`
        },
        {
          question: "Para comenzar el curso de Software Tester, ¿tengo que comprar el paquete de los cuatro módulos juntos o puedo ir comprando de a uno?",
          response: `Puedes optar por comprar el paquete completo de los cuatro módulos o ir comprando los módulos de uno en uno. El certificado se otorgará una vez que hayas aprobado los cuatro módulos.`
        },
        {
          question: "¿Qué áreas de tecnología abarca la propuesta de cursos que tiene Academy actualmente?",
          response: `En nuestra propuesta académica encontrarás una amplia variedad de cursos relacionados con diferentes áreas de la tecnología. Ofrecemos cursos en testing funcional, automatización, performance e inteligencia artificial, así como en inteligencia emocional y habilidades blandas necesarias para desarrollarse en la industria tecnológica.`
        },
        {
          question: "Si actualmente soy estudiante de Abstracta Academy o egresé, ¿tengo algún descuento si quiero comprar otros cursos?",
          response: `Sí. En Abstracta Academy creemos en el poder transformador de la educación y queremos acompañarte en tu crecimiento, porque sabemos que la educación continua es clave para el crecimiento personal y profesional. 
          <br/><br/>
          Como muestra de nuestro compromiso con tu desarrollo y para agradecerte por ser parte de nuestra comunidad, podrás disfrutar de un 15% de descuento en tu próximo curso.`
        },
        {
          question: "¿Cómo funciona el “programa de referidos” de Abstracta Academy?",
          response: `Si eres estudiante o egresaste de alguno de nuestros cursos, te invitamos a referir a personas que creas que puedan estar interesadas a estudiar en Abstracta Academy. Cuando las personas que refieres se inscriban y mencionen tu nombre como punto de contacto, recibirás un 20% de descuento en tu próximo curso. Importante: los beneficios no son acumulables.

          <br/><br/>
          Para aprovechar este beneficio, solo tienes que enviarnos un correo a <a href="mailto:hello@abstracta.academy" target="_blank"> hello@abstracta.academy</a>, y nos pondremos en contacto contigo lo antes posible.`
        },
        {
          question: "¿Qué es un voucher de INEFOP y cómo solicitarlo?",
          response: `El voucher es un instrumento excepcional que INEFOP ofrece como seguro de desempleo o en actividad en sectores en crisis, como resultado de una entrevista de orientación y de acuerdo con el perfil y el proyecto educativo laboral de la persona usuaria. Es posible utilizarlo únicamente en los casos en que no exista una propuesta de capacitación regular de INEFOP que se adapte al perfil de la persona que está buscando capacitarse. 
          <h6 style="margin-bottom: 1rem; margin-top: 2rem">¿Quiénes pueden acceder a este voucher? </h6>
          Para acceder a este subsidio, las personas deben cumplir con ciertos criterios establecidos por INEFOP. Esto incluye no tener empleo y haber estado inscrito en el Banco de Previsión Social (BPS) durante un período específico de tiempo. Asegúrate de estar al día con el BPS y de tener toda la documentación necesaria.
          <h6 style="margin-bottom: 1rem; margin-top: 2rem">¿Cuál es el monto del voucher?</h6>
          Desde el 21 de enero de 2021, el valor del voucher financiado por INEFOP es de 16.000 pesos uruguayos. Este monto máximo no excluye la realización de cursos de capacitación cuyo valor sea mayor. 
          <h6 style="margin-bottom: 1rem; margin-top: 2rem">¿Cuál es el procedimiento para solicitar un voucher?</h6>
          Contactános a <a href="mailto:hello@abstracta.academy" target="_blank"> hello@abstracta.academy</a> y te proporcionaremos todos los detalles.`
        },
        {
          question: "¿Puedo solicitar un reembolso del curso?",
          response: `Entendemos que pueden surgir situaciones en las que necesites solicitar un reembolso. Por eso, te invitamos a leer detenidamente nuestra política de reembolso para determinar si aplica a tu caso.
          <br/><br/> 
          <strong>A continuación, detallamos los criterios y procesos relacionados con los reembolsos:</strong>
          <h6 style="margin-bottom: 1rem; margin-top: 2rem">1. Condiciones de reembolso: </h6>
          <ul>
            <li><strong>Avance máximo permitido: </strong> Procesaremos solicitudes de reembolso solo si el avance del estudiante en el curso es inferior al 30%.</li>
            <li><strong>Definición de avance: </strong> Consideramos como avance si se ha accedido al contenido del curso (lecturas, videos y/o actividades evaluadas) y los registros reflejan un progreso del 30% o más.</li>
            <li><strong>No aplicable: </strong> Los reembolsos no estarán disponibles si el avance supera el 30% del contenido del curso.</li>
          </ul>
          <h6 style="margin-bottom: 1rem; margin-top: 2rem">2. Plazos y proceso de solicitud</h6>
          <ul>
            <li><strong>Plazo para solicitar: </strong> La solicitud debe enviarse dentro de los 3 días posteriores a la compra del curso.</li>
            <li><strong>Cómo enviar la solicitud: </strong> Envía un correo a <a href="mailto:hello@abstracta.academy" target="_blank">hello@abstracta.academy</a> con los siguientes datos:
              <ul>
                <li>Motivo de la solicitud.</li>
                <li>Información de contacto de la persona que realizó la compra.</li>
              </ul>
            </li>
          </ul>
          <h6 style="margin-bottom: 1rem; margin-top: 2rem">3. Proceso de reembolso: </h6>
          <ul>
            <li><strong>Revisión de la solicitud: </strong> Verificaremos que la solicitud cumpla con los criterios establecidos. El motivo deberá ser coherente y/o estar justificado.</li>
            <li><strong>Tiempo de respuesta: </strong> Si la solicitud es aprobada, procesaremos el reembolso dentro de los siguientes 5 días hábiles.</li>
            <li><strong>Método de reembolso: </strong> Si la solicitud es aprobada, realizaremos el reembolso a través del mismo medio de pago utilizado para la compra.</li>
          </ul>
          <h6 style="margin-bottom: 1rem; margin-top: 2rem">4. Excepciones: </h6>
            <ul>
              <li>Nos reservamos el derecho de rechazar solicitudes en casos de abuso de esta política o comportamiento fraudulento.</li>
              <li>Evaluaremos caso por caso las solicitudes que se presenten fuera del plazo o que no cumplan con los criterios establecidos.</li>
            </ul>
          <h6 style="margin-bottom: 1rem; margin-top: 2rem">5. Contacto: </h6>
              Para cualquier consulta adicional, nuestro equipo de soporte está disponible en <a href="mailto:hello@abstracta.academy" target="_blank">hello@abstracta.academy</a>.<br/><br/>Al realizar la compra de un curso en Abstracta Academy, aceptas automáticamente los términos y condiciones establecidos en nuestra política de reembolso.
          `
        },
      ]
    };
  },
  computed: {
    filteredFaqsList() {
      if (this.searchValue.length < 2) return this.faqList;
      const ratings = this.faqList.map((faq) => { return [stringSimilarity.compareTwoStrings(this.searchValue.toLowerCase(), faq.question.toLowerCase()), faq] })
      const minVal = 0.03;
      const results = ratings.filter(([r, faq]) => { return r > minVal }).sort((a, b) => b[0] - a[0]).map(([r, faq]) => faq);
      return results;
    }
  },
  methods: {
  },
  mounted() {
    this.faqList = this.faqList.map((faq, index) => {
      return { ...faq, id: index }
    })
  }
};
